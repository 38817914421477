<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Broadcast Portal</h1>
      <div class="mt-10 mx-6 pb-5">
        <div class="flex flex-wrap xl:-mx-4 mb-10">
          <div class="w-9/12 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div class="mx-10 mt-5 border-2 border-gray-200">
                <input
                  type="text"
                  placeholder="Enter Template Title"
                  class="p-3"
                />
              </div>
              <div class="px-6 py-4 border-t border-gray-200">
                <div class="border text-sm rounded-lg p-4 bg-white">
                  <medium-editor
                    :text="myText"
                    :options="options"
                    custom-tag="h2"
                    v-on:edit="applyTextEdit"
                    class="border-2 p-5 rounded-lg h-48"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-8 overflow-hidden">
              <div class="my-8 px-8 w-1/2 overflow-hidden">
                <input type="checkbox" class="mr-3" />Merchant groups
              </div>

              <div class="my-8 px-8 w-1/2 overflow-hidden">
                <button
                  class="p-2 pl-5 pr-5 transition-colors duration-700 transform bg-gray-500 float-right hover:bg-blue-400 text-gray-100 text-lg rounded-lg focus:border-4 border-gray-300"
                >
                  Publish
                </button>
              </div>
            </div>
            <div class="flex flex-wrap -mx-8 overflow-hidden">
              <div
                class="my-8 px-8 w-full overflow-hidden bg-blue-50 p-5 mx-5 rounded-lg text-sm"
              >
                <div class="d-block">
                  <input type="checkbox" class="mr-3" />Key Accounts
                </div>
                <div class="d-block">
                  <input type="checkbox" class="mr-3" />MPOS Users
                </div>
                <div class="d-block">
                  <input type="checkbox" class="mr-3" />Bank Transfer Users
                </div>
                <div class="d-block">
                  <input type="checkbox" class="mr-3" />Other Merchants
                </div>
              </div>
            </div>
          </div>
          <div class="w-3/12 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div class="">
                    <p class="font-bold text-blue-800">
                      Publish History
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      Shows Template use history
                    </p>
                  </div>
                </div>
              </div>
              <div class="bg-blue-50 px-6 py-4 my-1 mx-2">
                <div class="flex items-center pt-3 w-full">
                  <div class="w-full">
                    <p class="font-bold text-blue-800">
                      Key Accounts, MPOS Users
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      13 Nov, 2021
                    </p>
                    <p class="text-sm text-green-700 mt-1 text-right">
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
              <div class="bg-blue-50 px-6 py-4 my-1 mx-2">
                <div class="flex items-center pt-3 w-full">
                  <div class="w-full">
                    <p class="font-bold text-blue-800">
                      Key Accounts
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      13 Nov, 2021
                    </p>
                    <p class="text-sm text-green-700 mt-1 text-right">
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import editor from "vue2-medium-editor";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    "medium-editor": editor,
  },
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      myText: "Enter text here...",
    };
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/medium-editor/5.22.1/css/medium-editor.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/medium-editor/5.22.1/css/themes/flat.css");
.medium-editor-element {
  outline: none;
}

a,
a:hover,
section.splash h1 span,
.medium-editor-toolbar li button,
.color-1,
.color-2 {
  color: #4fc08d;
}

body,
pre,
.medium-editor-button-active b,
code {
  color: #2c3e50;
}

.medium-editor-button-active.medium-editor-button-active.medium-editor-button-active {
  background-color: #4fc08d;
}

pre,
code {
  background: #f8f8f8;
  width: 80vw;
}

pre#cdn,
code.data,
pre#usage {
  max-width: 900px;
}

code.data {
  margin-top: 50px;
}

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent #2c3e50 transparent;
}

.medium-editor-toolbar li button {
  border-right: 1px solid #2c3e50;
}

section.installation,
.medium-editor-toolbar,
.medium-editor-toolbar-anchor-preview,
.github-fork-ribbon {
  background: #2c3e50;
}

.medium-toolbar-arrow-under:after {
  border-color: #2c3e50 transparent transparent transparent;
}

#toolbar-placeholder {
  display: none;
}

.medium-editor-toolbar {
  transition: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
